$(document).ready(function() {
    $('#bbrc-confirm-delete').on('show.bs.modal', function(e) {
        var title = $(e.relatedTarget).data('title');
        if (title != undefined)
            $(this).find('.modal-title').html(title);

        var message = $(e.relatedTarget).data('message');
        if (message != undefined)
            $(this).find('.modal-body #message').html(message);

        $(this).find('.btn-ok').on('click', function () {
            e.preventDefault();

            var token = $('.delete-token').data('delete-token');
            if (token == undefined)
                token = $(e.relatedTarget).data('delete-token');

            var method = $(e.relatedTarget).data('method');
            if (method == undefined)
                method = 'DELETE';

            var form =
                $('<form>', {
                    'method': 'POST',
                    'action': $(e.relatedTarget).data('href')
                });

            var token =
                $('<input>', {
                    'type': 'hidden',
                    'name': '_token',
                    'value': token
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': method
                });

            form.append(token, hiddenInput).appendTo('body');
            form.submit()
        });
    });
});